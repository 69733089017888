import {gsap} from './../vendor/gsap/gsap.min';
import {ScrollTrigger} from './../vendor/gsap/scroll-trigger.min';
import Splitting from './../vendor/splitting';

gsap.registerPlugin(ScrollTrigger);

export const iniSplittingText = () => {
  const CURRENT_COLOR_DEFAULT = '#ffffff';

  const textBlocks = document.querySelectorAll('[data-splitting-words]');
  if (textBlocks.length === 0) {
    return;
  }

  textBlocks.forEach((block) => {
    const currentColor = block.dataset.textColor || CURRENT_COLOR_DEFAULT;

    new Splitting({
      target: block,
    });

    const words = block.querySelectorAll('.word');
    const timeline = gsap.timeline();

    timeline.to(words, {
      color: currentColor,
      stagger: {
        each: 0.1,
      },
    });

    ScrollTrigger.create({
      trigger: block,
      animation: timeline,
      start: 'top center',
      end: '+=100%',
      scrub: 0.9,
    });
  });
};
