import {ScrollLock} from '../utils/scroll-lock';

const scrollLock = new ScrollLock();
const burger = document.querySelector('[data-burger]');
const menu = document.querySelector('.main-nav--slide');

const onEscPress = (evt) => {
  if (evt.key === 'Escape' || evt.key === 'Esc') {
    evt.preventDefault();

    closeMenu();
  }
};

const openMenu = () => {
  burger.classList.add('is-open');
  menu.classList.add('is-open');
  scrollLock.disableScrolling();
  window.addEventListener('resize', onResize);
  window.addEventListener('keydown', onEscPress);
};

const closeMenu = () => {
  burger.classList.remove('is-open');
  menu.classList.remove('is-open');
  scrollLock.enableScrolling();
  window.removeEventListener('resize', onResize);
  window.removeEventListener('keydown', onEscPress);
};

const onResize = () => {
  closeMenu();
};

const onClickBurger = (evt) => {
  if (evt.target.closest('.header')) {

    if (evt.target.closest('[data-burger]').classList.contains('is-open')) {
      closeMenu();
    } else {
      openMenu();
    }
  }
};

const openNav = () => {
  if (!burger || !menu) {
    return;
  }

  burger.addEventListener('click', onClickBurger);
};

export {openNav};
