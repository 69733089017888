const headerMenu = document.querySelector('.header');
const transitionProp = 'transform 0.6s ease';
const breakpoint = window.matchMedia('(max-width: 1023px)');
let onScrollPage;

const toggleHeaderMenuState = (targetEl, heightEl, isDelete = false) => {
  let scrollPosition = 0;
  let startPoint = window.pageYOffset;

  if (isDelete) {
    targetEl.classList.remove('is-fixed');
    targetEl.style.transform = 'none';
    window.removeEventListener('scroll', onScrollPage);
    return;
  }

  onScrollPage = () => {
    scrollPosition = window.pageYOffset;
    if (scrollPosition > heightEl.offsetHeight && !targetEl.classList.contains('is-fixed')) {
      targetEl.style.transform = 'translateY(-' + heightEl.offsetHeight + 'px)';
      targetEl.style.transition = 'none';
      targetEl.classList.add('is-fixed');
      setTimeout(() => {
        targetEl.style.transition = transitionProp;
      }, 600);
    } else if (scrollPosition === 0 && targetEl.classList.contains('is-fixed')) {
      targetEl.style.transform = 'none';
      targetEl.classList.remove('is-fixed');
    } else if (startPoint < scrollPosition && scrollPosition > heightEl.offsetHeight) {
      targetEl.style.transform = 'translateY(-' + heightEl.offsetHeight + 'px)';
    } else if (startPoint > scrollPosition && scrollPosition > heightEl.offsetHeight) {
      targetEl.style.transform = 'none';
    }

    startPoint = scrollPosition;
  };

  window.addEventListener('scroll', onScrollPage);
};

const breakpointChecker = () => {
  if (breakpoint.matches) {
    toggleHeaderMenuState(headerMenu, headerMenu);
  } else {
    toggleHeaderMenuState(headerMenu, headerMenu, true);
  }
};

const fixHeader = () => {
  if (!headerMenu) {
    return;
  }

  breakpointChecker();
  breakpoint.addListener(breakpointChecker);
};

export {fixHeader};
