const screens = document.querySelectorAll('[data-animate]');
let elTop;
let windowHeight;

// eslint-disable-next-line no-undef
const returnAnimatePoint = (el) => {
  elTop = el.getBoundingClientRect().top;
  windowHeight = window.innerHeight;
  return windowHeight / 1.2 - elTop;
};

const trackingScreenBlock = () => {
  screens.forEach((screen) => {
    if (returnAnimatePoint(screen) > 0 && !screen.classList.contains('show')) {
      screen.classList.add('show');
    }
  });
};

const initAnimation = () => {
  const showAnimates = document.querySelectorAll('[data-animate="show"]');

  showAnimates.forEach((el) => {
    el.classList.add('show');
  });

  if (screens.length) {
    trackingScreenBlock();
    window.addEventListener('scroll', trackingScreenBlock);
    window.addEventListener('orientationchange', trackingScreenBlock);
  }

};

export {initAnimation};
