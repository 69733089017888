import {iosVhFix} from './utils/ios-vh-fix';
import {ieFix} from './utils/ie-fix';
import {polyfillObjectFit} from './utils/polyfill-object-fit';
import {initModals} from './modules/modals/init-modals';
import {initFaqAnimation} from './modules/init-faq-animation';
import {initFormValidate} from './modules/form/init-form-validate';
import {initAutoResizeTextarea} from './modules/form/auto-resize-textarea';
import {openNav} from './modules/open-nav';
import {fixHeader} from './modules/fix-header';
import {promoSlider} from './modules/promo-slider';
import {initTimer} from './modules/init-timer';
import {initDecks} from './modules/init-decks';
import {initHorizontalScroll} from './modules/init-horizontal-scroll';
import {initAnimationQuotes} from './modules/init-animation-quotes';
import {initAnimation} from './modules/init-animation';
import {videoChangePath} from './modules/video-change-path';
import {initAccordions} from './modules/accordion/init-accordion';
import {iniSplittingText} from './modules/init-splitting-text';


// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  iosVhFix();
  ieFix();
  polyfillObjectFit();
  openNav();
  fixHeader();
  initAccordions();
  initAnimation();
  videoChangePath();

  // Modules
  // ---------------------------------

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initModals();
    // initCustomSelect();
    initFormValidate();
    initAutoResizeTextarea();
    promoSlider();
    initFaqAnimation();
    initTimer();
    initDecks();
    initHorizontalScroll();
    initAnimationQuotes();
    iniSplittingText();
  });
});

// ---------------------------------

// ❗❗❗ обязательно установите плагины eslint, stylelint, editorconfig в редактор кода.

// привязывайте js не на классы, а на дата атрибуты (data-validate)

// вместо модификаторов .block--active используем утилитарные классы
// .is-active || .is-open || .is-invalid и прочие (обязателен нейминг в два слова)
// .select.select--opened ❌ ---> [data-select].is-open ✔️

// выносим все в дата атрибуты
// url до иконок пинов карты, настройки автопрокрутки слайдера, url к json и т.д.

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// используйте .closest(el)
