export default class ServicesControllerDeck {
  constructor(element, offset) {
    this._parent = element || document;
    this._scrollBlocks = this._parent.querySelectorAll('.js-scroll-block');
    this._screenWrapper = this._parent.querySelector('.js-block');
    this._blocksWrapper = this._parent.querySelector('.js-block-wrapper');
    this._heightBlockWithoutWrapper = this._screenWrapper.offsetHeight - this._blocksWrapper.offsetHeight;

    this._breakpoint = window.matchMedia('(max-width:767px)');
    this._offset = offset || 40;
    this._scaleValue = 0.035;

    this._onWindowScroll = this._onWindowScroll.bind(this);
    this._onWindowResize = this._onWindowResize.bind(this);
    this._breakpointMobileChecker = this._breakpointMobileChecker.bind(this);
  }

  _getMaxHeightBlocks() {
    let height = 0;
    this._scrollBlocks.forEach((block) => {
      block.style.height = null;
      const currentBlockHeight = block.scrollHeight;
      height = currentBlockHeight > height ? currentBlockHeight : height;
    });

    if (height !== this._heightScrollBlock) {
      this._heightScrollBlock = height;
    }
  }

  _setHeightScrollBlocks() {
    this._scrollBlocks.forEach((block) => {
      block.style.height = this._heightScrollBlock + 'px';
    });
  }

  _setBlockHeight() {
    this._getMaxHeightBlocks();
    this._setHeightScrollBlocks();

    this._windowHeight = window.innerHeight;
    const height = [...this._scrollBlocks].reduce((sum, item) => {
      return sum + item.scrollHeight + this._offset * 2;
    }, this._heightBlockWithoutWrapper);

    this._screenWrapper.style.height = height + 'px';
    this._blocksWrapper.style.paddingBottom = this._scrollBlocks.length * this._offset + this._heightScrollBlock + 'px';
  }

  _getCurrentScaleValue(topBlockValue, index) {
    const maxScaleValue = (this._scrollBlocks.length - 1 - index) * this._scaleValue;
    const scaleValue = (1 - (this._heightScrollBlock * (index + 1) + topBlockValue) / this._heightScrollBlock) * this._scaleValue;
    const currentScale = maxScaleValue < scaleValue ? 1 - maxScaleValue : 1 - scaleValue;
    return currentScale;
  }

  _moveBlock() {
    const topBlockValue = this._screenWrapper.getBoundingClientRect().top;

    this._scrollBlocks.forEach((block, index) => {
      const innerBlock = block.firstElementChild;
      const maxTop = (this._heightScrollBlock) * index;
      const currentScale = this._getCurrentScaleValue(topBlockValue, index);
      const currentOpacity = (topBlockValue + this._heightScrollBlock * (index + 1)) / this._heightScrollBlock;

      if (topBlockValue < 0 && topBlockValue > (maxTop * -1)) { // скролл выше верхней точки текущего элемента
        block.style.transform = `translate3d(-50%, ${topBlockValue}px, 0)`;
      } else if (topBlockValue < 0 && topBlockValue < (maxTop * -1)) { // скролл ниже верхней точки текущего элемента
        if (index < this._scrollBlocks.length - 1) {
          innerBlock.style.opacity = currentOpacity < 0 ? 0 : currentOpacity;
        }
        block.style.transform = `translate3d(-50%, -${maxTop}px, 0) scaleX(${currentScale})`;
      } else if (topBlockValue > 0) { // скролл выше блока
        block.style.transform = 'translate3d(-50%, 0px, 0) scaleX(1)';
        innerBlock.style.opacity = '1';
      }
    });
  }

  _setTransformBlocks() {
    this._scrollBlocks.forEach((block, index) => {
      block.style.top = (this._heightScrollBlock + this._offset) * index + 'px';
    });
  }

  _onWindowScroll() {
    this._moveBlock();
  }

  _onWindowResize() {
    this._setBlockHeight();
    this._setTransformBlocks();
    this._moveBlock();
  }

  _breakpointMobileChecker() {
    if (this._breakpoint.matches) {
      this._offset = 24;
      this._scaleValue = 0.1;
    } else {
      this._offset = 40;
      this._scaleValue = 0.035;
    }
  }

  _activateBlocksScroll() {
    this._setBlockHeight();
    this._setTransformBlocks();
    this._moveBlock();
    window.addEventListener('scroll', this._onWindowScroll);
    window.addEventListener('resize', this._onWindowResize);
  }

  init() {
    if (this._screenWrapper) {
      this._breakpoint.addListener(this._breakpointMobileChecker);
      this._breakpointMobileChecker();
      this._activateBlocksScroll();
    }
  }
}
