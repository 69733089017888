const videos = document.querySelectorAll('[data-video-mobile]');
const breakpoint = window.matchMedia('(max-width:767px)');

const videoChangePath = () => {
  if (!videos.length) {
    return;
  }

  videos.forEach((video) => {
    const videoSource = video.querySelector('source');
    const mobileVideoPath = video.dataset.videoMobile;
    const desctopVideoPath = video.dataset.videoDesctop;

    const breakpointChecker = () => {
      if (breakpoint.matches) {
        videoSource.setAttribute('src', mobileVideoPath);
        video.load();
      } else {
        videoSource.setAttribute('src', desctopVideoPath);
        video.load();
      }
    };

    breakpointChecker();
    breakpoint.addListener(breakpointChecker);
  });
};

export {videoChangePath};
