import {ServicesController} from '../utils/services-controller';

const results = document.querySelector('.results');

const initHorizontalScroll = () => {
  if (results) {
    const resultsScroll = new ServicesController(results);
    resultsScroll.init();
  }
};

export {initHorizontalScroll};
