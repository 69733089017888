export class ServicesControllerFade {
  constructor(parent) {
    this._parent = parent || document;
    this._scrollBlock = this._parent.querySelector('.js-fade-block');
    this._scrollItems = this._parent.querySelectorAll('.js-fade-item');
    this._bullet = this._parent.querySelector('.js-fade-bullet');
    this._progressBar = this._parent.querySelector('.js-fade-progress');

    this._offset = 1000; // высота скролла одного слайда в px
    this._transformValue = 80; // величина вертикального смещения в px
    this._animationScroll = 100; // диапазон скролла в котором будет происходить анимация в px

    this._maxWidthDasharray = Math.round(this._bullet.offsetWidth * Math.PI);
    this._progress = 0;
    this._index = 0;

    this._onWindowScroll = this._onWindowScroll.bind(this);
    this._onWindowResize = this._onWindowResize.bind(this);
  }

  _setScrollBlockHeight() {
    this._windowHeight = window.innerHeight;
    this._scrollBlock.style.height = this._windowHeight + (this._scrollItems.length) * this._offset + 'px';
  }

  _removeBlockStyle() {
    this._scrollBlock.style.height = '';
    this._scrollItems.forEach((item) => {
      const animationItems = item.querySelectorAll('.js-fade-animation');
      animationItems.forEach((animationItem) => {
        animationItem.style = '';
      });
    });
  }

  _addPaginationBullets() {
    const parent = this._bullet.parentElement;
    this._bullet.style.setProperty('--max-dash', this._maxWidthDasharray + 'px');
    parent.innerHTML = '';

    for (let i = 0; i < this._scrollItems.length; i++) {
      const copyBullet = this._bullet.cloneNode(true);
      copyBullet.setAttribute('aria-label', `Перейти к слайду ${i + 1}`);
      if (i === this._index - 1) {
        copyBullet.classList.add('active');
      }
      parent.appendChild(copyBullet);
    }

    this._bullets = this._scrollBlock.querySelectorAll('.js-fade-bullet');
  }

  _updateProgressBar() {
    const shift = this._index > 0 ? (this._offset * this._index - this._animationScroll) : 0;
    let progerssIndPx = this._progress * this._offset * this._bullets.length - shift;
    progerssIndPx = progerssIndPx < 0 ? 0 : progerssIndPx;
    const progressCircle = this._index > 0 ? progerssIndPx / this._offset * this._maxWidthDasharray : progerssIndPx / (this._offset - this._animationScroll) * this._maxWidthDasharray;
    this._bullets[this._index].style.setProperty('--current-dash', progressCircle + 'px');
  }

  _getCurrentIndex() {
    let setIndex = Math.floor(this._progress * this._scrollItems.length + this._animationScroll / this._offset);
    setIndex = setIndex < 0 ? 0 : setIndex;
    const currentIndex = setIndex > this._scrollItems.length - 1 ? this._scrollItems.length - 1 : setIndex;

    if (currentIndex !== this._index) {
      this._index = currentIndex;
      this.changeActiveBullet();
    }
  }

  _animationElements(item, progress, event) {
    const animationItems = item.querySelectorAll('.js-fade-animation');

    switch (event) {
      case 'bottomToUp':
        animationItems.forEach((animationItem, index) => {
          const step = 1 / animationItems.length;
          const currentProgress = (progress - step * index) * animationItems.length;
          if (progress > step * index && progress < step * (index + 1)) {
            animationItem.style.transform = `translate3d(0, -${currentProgress * this._transformValue}px, 0)`;
            animationItem.style.opacity = `${1 - currentProgress}`;
          }
        });
        break;
      case 'upToBottom':
        animationItems.forEach((animationItem, index) => {
          const step = 1 / animationItems.length;
          const currentProgress = (progress - step * index) * animationItems.length;
          if (progress > step * index && progress < step * (index + 1)) {
            animationItem.style.transform = `translate3d(0, ${(1 - currentProgress) * this._transformValue}px, 0)`;
            animationItem.style.opacity = `${currentProgress}`;
          }
        });
        break;
      case 'fadeUp':
        animationItems.forEach((animationItem) => {
          animationItem.style.transform = `translate3d(0, -${this._transformValue}px, 0)`;
          animationItem.style.opacity = 0;
        });
        break;
      case 'fadeBottom':
        animationItems.forEach((animationItem) => {
          animationItem.style.transform = `translate3d(0, ${this._transformValue}px, 0)`;
          animationItem.style.opacity = 0;
        });
        break;
      case 'visible':
        animationItems.forEach((animationItem) => {
          animationItem.style.transform = 'translate3d(0, 0, 0)';
          animationItem.style.opacity = 1;
        });
        break;
    }
  }

  _moveItems() {
    const blockPositionTop = this._scrollBlock.getBoundingClientRect().top;
    if (blockPositionTop < 0 && blockPositionTop > (this._scrollBlock.scrollHeight - window.innerHeight) * -1) {

      this._progress = (blockPositionTop * -1) / (this._scrollItems.length * this._offset);

      this._scrollItems.forEach((item, idx) => {
        const bottomStart = ((idx + 1) * this._offset - this._animationScroll * 2);
        const bottomEnd = ((idx + 1) * this._offset - this._animationScroll);
        const topStart = ((idx) * this._offset - this._animationScroll);
        const topEnd = ((idx) * this._offset);

        const bottomProgress = (-blockPositionTop - bottomStart) / (bottomEnd - bottomStart);
        const topProgress = (-blockPositionTop - topStart) / (topEnd - topStart);

        if (-blockPositionTop > bottomStart && -blockPositionTop < bottomEnd && idx < (this._scrollItems.length - 1)) { // анимация снизу вверх
          this._animationElements(item, bottomProgress, 'bottomToUp');
        } else if (-blockPositionTop > topStart && -blockPositionTop < topEnd && idx > 0) { // анимация сверху вниз
          this._animationElements(item, topProgress, 'upToBottom');
        } else if (-blockPositionTop > bottomEnd && idx < (this._scrollItems.length - 1)) { // скрытие слайда в верхней части
          this._animationElements(item, topProgress, 'fadeUp');
        } else if (-blockPositionTop < topStart) { // скрытие слайда в нижней части
          this._animationElements(item, topProgress, 'fadeBottom');
        } else if (-blockPositionTop > topEnd && -blockPositionTop < bottomStart) { // активный слайд между анимациями
          this._animationElements(item, topProgress, 'visible');
        }
      });
    } else if (blockPositionTop < (this._scrollBlock.scrollHeight - this._windowHeight) * -1) {
      this._scrollItems.forEach((item, index) => {
        if (index < this._scrollItems.length - 1) {
          this._animationElements(item, 0, 'fadeUp');
        }
      });
      this._progress = 1;
    } else {
      this._scrollItems.forEach((item, index) => {
        if (index > 0) {
          this._animationElements(item, 0, 'fadeBottom');
        }
      });
      this._progress = 0;
    }
  }

  _onWindowScroll() {
    this._moveItems();
    this._updateProgressBar();
    this._getCurrentIndex();
  }

  _onWindowResize() {
    this._setScrollBlockHeight();
  }

  changeActiveBullet(index) {
    const value = isNaN(index) ? this._index : index;
    this._bullets.forEach((elem) => {
      elem.classList.remove('active');
    });
    this._bullets[value].classList.add('active');
  }

  activatedBlockScroll() {
    this._setScrollBlockHeight();
    this._updateProgressBar();
    this.changeActiveBullet();
    this._moveItems();
    window.addEventListener('scroll', this._onWindowScroll);
    window.addEventListener('resize', this._onWindowResize);
  }

  deactivatedBlockScroll() {
    this._removeBlockStyle();
    this._updateProgressBar(0);
    window.removeEventListener('scroll', this._onWindowScroll);
    window.removeEventListener('resize', this._onWindowResize);
  }

  init() {
    this._addPaginationBullets();
    this.activatedBlockScroll();
  }
}
