import {inclineWord} from '../utils/incline-word';

const timerBlock = document.querySelector('[data-timer-date]');
let timer;

const getTimeRemaining = (time) => {
  const diff = (Date.parse(time) - Date.parse(new Date())) / 1000; // разница в секундах

  if (diff <= 0) {
    return false;
  }

  const days = Math.floor(diff / 60 / 60 / 24);
  const hours = Math.floor((diff / 60 / 60) % 24);
  const minuts = Math.floor((diff / 60) % 60);
  const seconds = Math.floor(diff % 60);

  return {
    days,
    hours,
    minuts,
    seconds,
  };
};

const updateTime = (el, value, typeEnding) => {
  const number = el.querySelector('[data-timer-number]');
  const name = el.querySelector('[data-timer-name]');
  number.textContent = ('0' + value).slice(-2);
  name.textContent = inclineWord(value, typeEnding);
};

const initTimer = () => {
  if (!timerBlock) {
    return;
  }

  const dayWrap = timerBlock.querySelector('[data-timer-day]');
  const hourWrap = timerBlock.querySelector('[data-timer-hour]');
  const minutWrap = timerBlock.querySelector('[data-timer-minut]');
  const secondWrap = timerBlock.querySelector('[data-timer-second]');

  timer = setInterval(() => {
    const time = getTimeRemaining(timerBlock.dataset.timerDate);
    if (!time) {
      updateTime(dayWrap, '0', 'dayEndings');
      updateTime(hourWrap, '0', 'hourEndings');
      updateTime(minutWrap, '0', 'minutEndings');
      updateTime(secondWrap, '0', 'secondEndings');
      clearInterval(timer);
      return;
    }
    updateTime(dayWrap, time.days, 'dayEndings');
    updateTime(hourWrap, time.hours, 'hourEndings');
    updateTime(minutWrap, time.minuts, 'minutEndings');
    updateTime(secondWrap, time.seconds, 'secondEndings');
  }, 1000);
};

export {initTimer};
