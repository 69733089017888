const slider = document.querySelector('.promo__slider .swiper-container');
const swipeDelay = 5000;
const swipeSpeed = 1000;
let timer;
let timeLeft = 0;

const filling = (swiper, currentTime = 0) => {
  const activeBullet = slider.querySelector('.swiper-pagination-bullet-active');
  let start = Date.now(); // запомнить время начала
  const delay = currentTime !== 0 ? currentTime : swipeDelay; // оставшееся время воспроизведения (currentTime будет при запуске таймера после остановки)

  const draw = (timePassed) => {
    const currentWidth = (((1 - delay / swipeDelay) + timePassed / swipeDelay) * 100) + '%'; // расчёт ширины заполненой части с учётом остановки таймера
    activeBullet.style.setProperty('--width', currentWidth);
  };

  // обнуляет старый таймер если должен запуститься новый (при перелистывании слайдов)
  if (timer) {
    clearInterval(timer);
  }

  timer = setInterval(function () {
    // сколько времени прошло с начала анимации?
    let timePassed = Date.now() - start;
    timeLeft = delay - timePassed; // сохраняет оставшееся время

    if (timePassed >= delay) {
      clearInterval(timer); // закончить анимацию через заданное время
      swiper.slideNext(swipeSpeed, true);
      activeBullet.style.setProperty('--width', 0);
      filling(swiper);
      return;
    }

    // отрисовать анимацию на момент timePassed, прошедший с начала анимации
    draw(timePassed);

  }, 50);
};

const promoSlider = () => {
  if (!slider) {
    return;
  }

  const pagination = slider.querySelector('.swiper-pagination');

  // eslint-disable-next-line no-undef
  const swiper = new Swiper(slider, {
    slidesPerView: 1,
    loop: true,
    grabCursor: true,
    pagination: {
      el: pagination,
      clickable: true,
    },
  });

  const bullets = slider.querySelectorAll('.swiper-pagination-bullet');

  const onSlideChange = () => {
    timeLeft = 0;
    if (bullets) {
      bullets.forEach((bullet) => {
        bullet.style.setProperty('--width', 0);
      });
    }

    filling(swiper, false);
  };

  swiper.on('slideChange', () => {
    onSlideChange();
  });

  slider.addEventListener('mousedown', () => {
    if (timer) {
      clearInterval(timer);
    }
  });

  slider.addEventListener('touchstart', () => {
    if (timer) {
      clearInterval(timer);
    }
  });

  slider.addEventListener('mouseup', () => {
    if (timer) {
      filling(swiper, timeLeft);
    }
  });

  slider.addEventListener('touchend', () => {
    if (timer) {
      filling(swiper, timeLeft);
    }
  });

  filling(swiper);
};

export {promoSlider};
