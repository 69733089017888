import ServicesControllerDeck from '../utils/services-controller-deck';
const about = document.querySelector('.about');

const initDecks = () => {
  if (about) {
    const aboutDecks = new ServicesControllerDeck(about, 40);
    aboutDecks.init();
  }
};

export {initDecks};
