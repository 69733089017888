import {ServicesControllerFade} from '../utils/services-controller-fade';

const initAnimationQuotes = () => {
  const quotes = document.querySelector('.main-quotes');
  if (!quotes) {
    return;
  }

  const scrollFade = new ServicesControllerFade();
  const breakpoint = window.matchMedia('(max-width:1023px)');
  const swipeSpeed = 500;
  let swiper;

  const onClickBullet = (evt) => {
    if (evt.target.closest('.main-quotes__bullet')) {
      const bullet = evt.target.closest('.main-quotes__bullet');
      const parent = bullet.closest('.main-quotes__bullets');
      const bullets = parent.querySelectorAll('.main-quotes__bullet');
      if (!bullet.classList.contains('active')) {
        swiper.slideTo([...bullets].indexOf(bullet), swipeSpeed);
      }
    }
  };

  const initSwiper = (slider) => {
    if (!quotes) {
      return;
    }

    scrollFade.deactivatedBlockScroll();

    // eslint-disable-next-line no-undef
    swiper = new Swiper(slider, {
      slidesPerView: 1,
      spaceBetween: 20,
      speed: swipeSpeed,
      breakpoints: {
        768: {
          spaceBetween: 40,
        },
      },
    });

    window.addEventListener('click', onClickBullet);
    scrollFade.changeActiveBullet(0);
    swiper.on('slideChange', () => {
      scrollFade.changeActiveBullet(swiper.activeIndex);
    });
  };

  const slider = quotes.querySelector('.swiper-container');
  scrollFade.init();

  const breakpointChecker = () => {
    if (breakpoint.matches) {
      if (!swiper) {
        initSwiper(slider);
      }
    } else {
      if (swiper) {
        swiper.destroy(true);
        swiper = null;
        window.removeEventListener('click', onClickBullet);
      }
      scrollFade.activatedBlockScroll();
    }
  };

  breakpointChecker();
  breakpoint.addListener(breakpointChecker);
};

export {initAnimationQuotes};
