const initFaqAnimation = () => {
  const BREAKPOINT_TABLET = window.matchMedia('(max-width: 1023px)');

  const parent = document.querySelector('.faq-block');
  if (!parent) {
    return;
  }

  const contents = parent.querySelectorAll('.faq-block__answer');
  if (!contents) {
    return;
  }

  const links = parent.querySelectorAll('.faq-block__question');
  if (!links) {
    return;
  }

  const mobileButton = parent.querySelector('.faq-block__mobile-button');
  const menu = parent.querySelector('.faq-block__question-wrapper');

  const removeActiveStatus = () => {
    const activeTab = parent.querySelector('.active[data-answer]');

    if (activeTab) {
      activeTab.classList.remove('active');
    }

    const active = parent.querySelectorAll('.active[data-question]');
    if (active) {
      active.forEach((item) => {
        item.classList.remove('active');
      });
    }
  };

  const setActiveStatus = (el) => {
    const t = `[data-question = "${el.dataset.answer}"]`;
    if (!el.classList.contains('active')) {
      parent.querySelector(t).classList.add('active');
      el.classList.add('active');
      el.scrollBy({
        top: 50,
        behavior: 'smooth',
      });
    }
  };

  const setContentActiveStatus = (evt) => {
    const li = evt.target.closest('[data-question]');
    const t = `[data-answer = "${li.dataset.question}"]`;
    if (!li.classList.contains('active')) {
      parent.querySelector(t).classList.add('active');
      li.classList.add('active');
    }
  };

  const scrollInToView = (evt) => {
    const blockID = evt.target.getAttribute('href');
    evt.target.classList.add('active');
    const coord = document.querySelector(blockID).getBoundingClientRect();
    const BREAKPOINT_MOBILE = window.matchMedia('(max-width: 767px)');
    if (!BREAKPOINT_MOBILE.matches) {
      window.scrollBy({
        top: coord.top - 50,
        behavior: 'smooth',
      });
    }
  };

  const initMobileNav = () => {
    const active = parent.querySelector('.active[data-question]');
    if (active) {
      mobileButton.querySelector('span').textContent = active.textContent;
    } else {
      mobileButton.querySelector('span').textContent = links[0].textContent;
      links[0].classList.add('active');
      contents[0].classList.add('active');
    }
  };

  const handleClickMenuLink = (evt) => {
    evt.preventDefault();
    menu.classList.remove('open');
    mobileButton.classList.remove('open');
    removeActiveStatus();
    setContentActiveStatus(evt);
    scrollInToView(evt);
    initMobileNav();
  };

  const documentKeydownHandler = (evt) => {
    const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

    if (isEscKey) {
      evt.preventDefault();
      menu.classList.remove('open');
      mobileButton.classList.remove('open');
    }
  };

  const documentClickHandler = (evt) => {
    const target = evt.target;

    if (!target.closest('.open')) {
      menu.classList.remove('open');
      mobileButton.classList.remove('open');
    }
  };

  const handleClickMobileButton = (evt) => {
    evt.preventDefault();
    if (!evt.target.classList.contains('open')) {
      evt.target.classList.add('open');
      menu.classList.add('open');
      links.forEach((item) => {
        item.addEventListener('click', handleClickMenuLink);
      });
      document.addEventListener('keydown', documentKeydownHandler);
      document.addEventListener('click', documentClickHandler);
    } else {
      evt.target.classList.remove('open');
      menu.classList.remove('open');
      links.forEach((item) => {
        item.removeEventListener('click', handleClickMenuLink);
      });
      document.removeEventListener('keydown', documentKeydownHandler);
      document.removeEventListener('click', documentClickHandler);
    }
  };

  const handleScroll = () => {
    contents.forEach((item) => {
      let coord = item.getBoundingClientRect().top;
      if (coord < 200) {
        removeActiveStatus(item);
        setActiveStatus(item);
      }
    });
  };

  const setNavVersion = (isMobile) => {
    if (!isMobile) {
      links.forEach((item) => {
        item.addEventListener('click', scrollInToView);
      });
      window.addEventListener('scroll', handleScroll);
    }
    if (isMobile) {
      const BREAKPOINT_MOBILE = window.matchMedia('(max-width: 767px)');
      initMobileNav();
      mobileButton.addEventListener('click', handleClickMobileButton);
      if (!BREAKPOINT_MOBILE.matches) {
        window.addEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    }
  };

  const breakpointChecker = () => {
    if (BREAKPOINT_TABLET.matches) {
      const isMobile = true;
      setNavVersion(isMobile);
    } else {
      const isMobile = false;
      setNavVersion(isMobile);
    }
  };

  BREAKPOINT_TABLET.addListener(breakpointChecker);

  breakpointChecker();
};

export {initFaqAnimation};
